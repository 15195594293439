// Mapbox
export const VERSION = '0.1.0'

export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

export const PROJECT_TITLE = 'CCORE Earth Observation Support Dashboard'

export const WEATHER_SERVICE = 'https://s11054z10h.execute-api.us-east-2.amazonaws.com/default/get-historical-observation'

export const CONFIG_ROOT = `${window.location.origin}`

export const defaultViewport = {
  latitude: 47.575846,
  longitude: -52.734720,
  zoom: 9,
}
