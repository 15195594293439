import React from "react";
import {RequireNewPassword} from "aws-amplify-react";

import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "./common";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";


class CustomRequireNewPassword extends RequireNewPassword {

    showComponent(theme) {
        let fetching
		const { hide, classes } = this.props;
		if (hide && hide.includes(CustomRequireNewPassword)) {
			return null;
		}

		return (
  <Container component='main' maxWidth='xs'>
                <div className={classes.paper}>
                    <div className={classes.wrapper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        {fetching && (
                            <CircularProgress size={55}
                                              className={classes.fabProgress}/>
                        )}
                    </div>
                    <Typography component='h1' variant='h5'>
                        Update Password
                    </Typography>
                    {this.errorText && (
                        <Typography component='h1' variant='subtitle1'>
                            {this.errorText}
                        </Typography>
                    )}


                <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            name='password'
                            label='New Password'
                            type='password'
                            id='password'
                            key='password'
                            onChange={this.handleInputChange}
                            // autoComplpete="current-password"
                            value={undefined}
                        />
                        <Button onClick={this.change}
                                fullWidth
                                id='submit-reset'
                                variant='contained'
                                color='primary'
                                className={classes.submit}>
							Change
						</Button>

                </div>
  </Container>
		);
	}

}

CustomRequireNewPassword.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CustomRequireNewPassword)
