// @flow
import React from 'react'
import Provider from './Provider'
import theme from './theme'
import NavigationContainer from './navigation/NavigationContainer'
import awsconfig from './aws-exports'
import Amplify from 'aws-amplify'
import { withAuthenticator, ConfirmSignIn } from 'aws-amplify-react'
import CustomSignIn from "./components/utility/CustomLogin";
import CustomForgotPassword from "./components/utility/CustomForgot";
import CustomRequireNewPassword from "./components/utility/CustomRequireNewPassword";

if(awsconfig){
    Amplify.configure(awsconfig);
}




function App() {
  return (
    <Provider theme={theme}>
      <NavigationContainer />
    </Provider>
  );
}


export default withAuthenticator(App, false,[
    <CustomSignIn />,
    <ConfirmSignIn/>,
    <CustomForgotPassword/>,
    <CustomRequireNewPassword />], null );
