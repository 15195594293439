//@flow

import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'

import dataDuck from '../redux/dataDuck'

const dataEpic = (action$, state$) =>
      action$.pipe(
          ofType(dataDuck.actions.dataRequest),
          map(action => {
              try {
                  console.log(action)
              } catch (error) {
                  console.warn(error)
              }

              return dataDuck.actions.dataSuccess()
          })
      )


export default dataEpic
