import React from 'react'
import {ForgotPassword} from "aws-amplify-react";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "./common";

class CustomForgotPassword extends ForgotPassword{
     constructor(props) {
        super(props);
        this.errorText = ''
        this.send = this.send.bind(this);
		this.submit = this.submit.bind(this);

		this._validAuthStates = ['forgotPassword'];
		this.state = { delivery: null };
    }


    error(err: any) {
        this.errorText = this.errorMessage(err)
        super.error(err);
    }

    sendView(){
        const {classes} = this.props
        return (
            <div>
                <form className={classes.form} noValidate>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            id='username'
                            label='Email Address'
                            name='username'
                            key='username'
                            onChange={this.handleInputChange}
                            // autoComplete="email"
                            autoFocus
                        />
                </form>
                <Button
                fullWidth
                id='sign-in-button'
                variant='contained'
                color='primary'
                className={classes.submit}
                onClick={this.send}>
                        Send Code
                </Button>
        </div>
        )
    }

    submitView(){
                const {classes} = this.props

		return (
			<div>
                        <form className={classes.form} noValidate>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            id='code'
                            label='Reset Code'
                            name='code'
                            key='code'
                            onChange={this.handleInputChange}
                            // autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            name='password'
                            label='Password'
                            type='password'
                            id='password'
                            key='password'
                            onChange={this.handleInputChange}
                            // autoComplpete="current-password"
                            value={undefined}
                        />
                </form>
                        <Button
                              fullWidth
                                id='submit-reset'
                                variant='contained'
                                color='primary'
                                className={classes.submit}
								onClick={this.submit}
							>
							    Submit
							</Button>


			</div>
		);
    }

    //NOTE it will not display the component extended default unless i implement a gui.
    // this pulls the log in from story core and adapts it back to a react class format.
    showComponent(theme) {
        let fetching

		const { hide, authData = {}, classes } = this.props;
		if (hide && hide.includes(CustomForgotPassword)) {
			return null;
		}
		// return (<div>THIS DANG COMPUTER</div>)


		return (


                        <Container component='main' maxWidth='xs'>
                <div className={classes.paper}>
                    <div className={classes.wrapper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        {fetching && (
                            <CircularProgress size={55}
                                              className={classes.fabProgress}/>
                        )}
                    </div>
                    <Typography component='h1' variant='h5'>
                        Reset your password
                    </Typography>
                    {this.errorText && (
                        <Typography component='h1' variant='subtitle1'>
                            {this.errorText}
                        </Typography>
                    )}
                        {this.state.delivery || authData.userName
						? this.submitView()
						: this.sendView()}
                     <Grid item xs>
                <Link
                  variant='body2'
                  onClick={() => super.changeState("signIn")}
                >
                  Back to Sign In
                </Link>
              </Grid>
                </div>

            </Container>



    )
	}






}

CustomForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CustomForgotPassword)

