// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d3cbcf22-5d9e-4aeb-a514-02d1c1ca239a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vc14xXt7b",
    "aws_user_pools_web_client_id": "5tdkdem6rn2tus0v6p28f8ms4r",
    "oauth": {},
};


export default awsmobile;
