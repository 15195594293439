//@flow

import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import {SignIn} from "aws-amplify-react";
import {styles} from "./common";


class CustomSignIn extends SignIn {
    constructor(props) {
        super(props);
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
        // this.classes =  useStyles()
        this.errorText = ''
        this.classes = {}
    }

    signIn(event: any): Promise<void> {
        try {
            return super.signIn(event);
        }
        catch(error){
            console.log(error)
        }
    }

    error(err: any) {
        this.errorText = this.errorMessage(err)
        super.error(err);
    }


    //NOTE it will not display the component extended default unless i implement a gui.
    // this pulls the log in from story core and adapts it back to a react class format.
    showComponent(theme) {
        let fetching
        let {classes} = this.props
        return (
            <Container component='main' maxWidth='xs'>
                <div className={classes.paper}>
                    <div className={classes.wrapper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        {fetching && (
                            <CircularProgress size={55}
                                              className={classes.fabProgress}/>
                        )}
                    </div>
                    <Typography component='h1' variant='h5'>
                        Sign in
                    </Typography>
                    {this.errorText && (
                        <Typography component='h1' variant='subtitle1'>
                            {this.errorText}
                        </Typography>
                    )}
                    <form className={classes.form} noValidate>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            id='username'
                            label='Email Address'
                            name='username'
                            key='username'
                            onChange={this.handleInputChange}
                            // autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            name='password'
                            label='Password'
                            type='password'
                            id='password'
                            key='password'
                            onChange={this.handleInputChange}
                            // autoComplpete="current-password"
                            value={undefined}
                        />


                        {/*<FormControlLabel*/}
                        {/*    control={<Checkbox value='remember' color='primary'/>}*/}
                        {/*    label='Remember me'*/}
                        {/*/>*/}
                        <Button
                            fullWidth
                            id='sign-in-button'
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            onClick={this.signIn}
                        >
                            Sign In
                        </Button>
                    </form>
                     <Grid item xs>
                <Link
                  variant='body2'
                  onClick={() => super.changeState("forgotPassword")}
                >
                  Forgot password?
                </Link>
              </Grid>
                </div>

            </Container>
        )
    }


}

CustomSignIn.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CustomSignIn)
