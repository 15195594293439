import React, { type Node } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
      height: '8vh',
  },
  title: {
    flexGrow: 1
  }
}))

function Header ({
  title = '',
  shortTitle = '',
  children
}: {
  title?: string,
  shortTitle?: string,
  children?: Node
}) {
  const classes = useStyles()
  return (
    <AppBar position='fixed' className={classes.appBar} elevation={0}>
      <Toolbar>
        <Typography variant='h6' noWrap className={classes.title}>
          <Hidden smDown>{title}</Hidden>
          <Hidden mdUp>{shortTitle}</Hidden>
        </Typography>
        {children}
      </Toolbar>

    </AppBar>
  )
}

export default Header
