// @flow

import React, {Fragment} from 'react'
import { Route } from 'react-router-dom'
import loadable from '@loadable/component'
import {Switch} from "react-router";
import Footer from "../components/Footer";
import CCoreLogo from "../components/CCoreLogo";
import Header from "../components/Header";
import {PROJECT_TITLE, VERSION} from "../config";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import Amplify from "aws-amplify";
const ProjectRoute = loadable(() => import('../routes/ProjectRoute'))
const MapRoute = loadable(() => import('../routes/MapRoute'))
const DefaultRoute = loadable(() => import('../routes/DefaultRoute'))
const OrderRoute = loadable(() => import('../routes/OrderRoute'))

  const handleLogout = async () => {
    await Amplify.Auth.signOut()
  }


function NavigationContainer () {
  return (
      <Fragment>

        <Header title={`${PROJECT_TITLE} (${VERSION})`}  >
          <IconButton color="inherit" onClick={handleLogout}>
             <ExitToAppIcon  />
          </IconButton>
      </Header>

          <div style={{'top': '8vh', 'position':'absolute', 'padding-bottom': '10vh', 'height': '86vh', 'overflow-y': 'scroll', 'width': '100vw', 'margin': '10px'}}>
          <Switch>
            <Route path='/order' component={OrderRoute} />
            <Route path='/map' component={MapRoute} />
            <Route exact path='/:id' component={ProjectRoute} />

            <Route path='/' component={DefaultRoute} />
          </Switch>
      </div>
        <Footer item xs={1}>
            <CCoreLogo width={'10vw'} />
        </Footer>
      </Fragment>
  )
}

export default NavigationContainer
