// @flow

import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {createEpicMiddleware, combineEpics} from "redux-observable";

import map from "./mapDuck"
import data from "./dataDuck"
import dataEpic from "./dataEpic";

const epicMiddleware = createEpicMiddleware()

const rootEpic = (...args) =>
  combineEpics(dataEpic)(...args)

const reducer = combineReducers({
  map: map.reducer,
  data:data.reducer
})

const store = configureStore({
  reducer: reducer,
  middleware: [epicMiddleware],
  devTools: true
})
epicMiddleware.run(rootEpic)

window.store = store

export default store
