//@flow
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
    observations: {},
    acquisitionTime: ""
}

function dataRequest (state, action) {

    return state
}

function dataSuccess (state, action) {


    return state
}

function addObservation(state, action){
    const {payload} = action
    return {...state, observations:{...state.observations, ...payload}}
}

function setAcqTime(state, action){
    const {payload} = action
    return {...state, acquisitionTime: payload}
}

const data = createSlice({
  name: 'data',
  initialState,
  reducers: {
      dataRequest,
      dataSuccess,
      addObservation,
      setAcqTime
  }
})

export default data
