// @flow

import React from 'react'
import Img from 'react-image'

function handleClick () {
  window.open('https://c-core.ca')
}

function CCoreLogo ({
  width = '100vw',
  onClick = handleClick
}: {
  width?: string,
  onClick?: Function
}) {
  return (
    <Img
      onClick={onClick}
      src='/images/c-core.png'
      style={{ width: width, cursor: 'pointer' }}
    />
  )
}

export default CCoreLogo
