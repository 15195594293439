import React from 'react'
import Img from 'react-image'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  logoImg: {
    padding: '12px'
  }
}))

function handleClick (url) {
  window.open(url)
}

function SponsorLogo ({
  height = '45px',
  marginTop = '0px',
  marginBottom = '10px',
  onClick = handleClick,
  link = 'https://c-core.ca',
  src = '/images/c-core.png'
}: {
  width?: string,
  onClick?: Function
}) {
  const classes = useStyles()
  return (
    <Img
      className={classes.logoImg}
      onClick={() => onClick(link)}
      src={src}
      style={{
        height: height,
        marginTop: marginTop,
        marginBottom: marginBottom,
        cursor: 'pointer'
      }}
    />
  )
}

export default SponsorLogo
