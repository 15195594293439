import React, { type Node } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import 'typeface-roboto'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import defaultStore from './redux/store'
import defaultTheme from './theme'

const ProviderWrapper = (
  {
    children,
    store=defaultStore,
    theme=defaultTheme
  }:
  {
    +children: Node,
    store?: {},
    theme?: {}
  }
) => (
  <Provider store={defaultStore}>

      <ThemeProvider theme={defaultTheme}>
          <Router>
            <CssBaseline />
            { children }
          </Router>
      </ThemeProvider>

  </Provider>
)

export default ProviderWrapper
